import React, { useState } from "react";
import b_ from "b_";
import { TaskStatus } from "../gql/types";
import { useArchive, useRetry, useTaskList } from "./hooks";
import { StatusToggles } from "./StatusToggles";
import { TimeDiff } from "../TimeDiff";
import { TaskStatusView } from "../TaskStatus";

import "./styles.scss";

const b = b_.with("tasks");

export const TaskList: React.FC = () => {
  const [selectedStatuses, setStatuses] = useState<TaskStatus[]>([
    TaskStatus.Idle,
    TaskStatus.Pending,
    TaskStatus.Error,
  ]);
  const { list } = useTaskList(selectedStatuses);
  const { archive } = useArchive();
  const { retry } = useRetry();
  return (
    <div>
      <h2>Task List</h2>
      <StatusToggles
        selectedStatuses={selectedStatuses}
        setStatuses={setStatuses}
      />
      <div className={b("table-container")}>
        <table className={b("table")}>
          <thead>
            <tr>
              <th>Status</th>
              <th>Name</th>
              <th>Time</th>
              <th>User</th>
              <th>Result</th>
            </tr>
          </thead>
          <tbody>
            {list?.map((task) => (
              <tr key={task.id}>
                <td>
                  <TaskStatusView>{task.status}</TaskStatusView>
                </td>
                <td>{task.name}</td>
                <td>
                  <div title={task.executeAt}>
                    <TimeDiff format="HH:mm">{task.executeAt}</TimeDiff>
                  </div>
                </td>
                <td>{task.userId ? <a href={`/user/${task.userId}`}>{task.email || task.userId}</a>: task.email}</td>
                <td>{task.result}</td>
                <td>
                  {task.status === TaskStatus.Error && (
                    <div className={b('buttons')}>
                      <button onClick={() => archive(task.id)}>Archive</button>
                      <button onClick={() => retry(task.id)}>Retry</button>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
