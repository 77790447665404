import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { from } from "@apollo/client";
import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";

export const generateClient = (uri: string) => {
  const httpLink = createHttpLink({
    uri,
  });

  const authLink = setContext((_, { headers }) => {
    const token = document.cookie
      .split(";")
      .map((x) => x.trim())
      .find((x) => x.match(/^access_token/))
      ?.split("=")[1];
    return {
      headers: {
        ...headers,
        authorization: token,
      },
    };
  });

  const errorLink = onError(({ graphQLErrors }) => {
    const isUnauthorized = (graphQLErrors || []).some(
      (error) => error.message === "Need authorization"
    );
    const isLoginPage = window.location.pathname !== "/login";
    if (isUnauthorized && isLoginPage) {
      window.location.href = "/login";
    }
  });

  return new ApolloClient({
    link: from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache(),
  });
};
