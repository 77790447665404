export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSON: any;
  JSONObject: any;
};

export type AddConfirmAppointmentTaskInput = {
  chatId: Scalars['String'];
  /** in minutes */
  delay: Scalars['Int'];
  email: Scalars['String'];
  serviceId: Scalars['String'];
};

export type AddUserInput = {
  chatId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
};

export type Admin = {
  __typename?: 'Admin';
  email: Scalars['String'];
};

export type Appointment = {
  __typename?: 'Appointment';
  code?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
};

export type AppointmentInput = {
  code: Scalars['String'];
  date: Scalars['String'];
  email: Scalars['String'];
  time: Scalars['String'];
};

export type AvailableCountInSchedule = {
  __typename?: 'AvailableCountInSchedule';
  checkedAt: Scalars['String'];
  count: Scalars['Int'];
  nearestDate?: Maybe<Scalars['String']>;
};

export type CancelPreAppointmentInput = {
  /** Delay in minutes */
  delay?: InputMaybe<Scalars['Int']>;
  userId: Scalars['String'];
};

export type ChangePasswordInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type City = {
  __typename?: 'City';
  country?: Maybe<Country>;
  id: Scalars['String'];
  name: Scalars['String'];
};

export type Confirmation = {
  __typename?: 'Confirmation';
  data: Scalars['JSONObject'];
  user: User;
};

export type ConfitmPreAppointmentInput = {
  /** Delay in minutes */
  delay?: InputMaybe<Scalars['Int']>;
  userId: Scalars['String'];
};

export type Country = {
  __typename?: 'Country';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type EmailForwardingConfirmationInput = {
  chatId?: InputMaybe<Scalars['String']>;
  codeOrLink: Scalars['String'];
};

export type FullUserInput = {
  dob: Scalars['String'];
  extraFields?: InputMaybe<Scalars['JSON']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  patronymic?: InputMaybe<Scalars['String']>;
};

export type MidPassService = {
  __typename?: 'MidPassService';
  alternativeName?: Maybe<Scalars['String']>;
  city?: Maybe<City>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type Money = {
  __typename?: 'Money';
  currency: Scalars['String'];
  value: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addConfirmAppointmentTask: User;
  addUser: Scalars['String'];
  addUserToWaitingList: Scalars['String'];
  archiveUser: Scalars['String'];
  attachWaitingAppointmentToUser: Scalars['String'];
  cancelPreAppointment: Scalars['String'];
  changePasssword: Scalars['String'];
  cleanUserData: Scalars['String'];
  confirmEmailForwarding: Scalars['String'];
  confirmPreAppointment: Scalars['String'];
  disableTask: Scalars['String'];
  offerPreAppointment: Scalars['String'];
  rejectPreAppointment: Scalars['String'];
  retryTask: Scalars['String'];
  setAppointment: Scalars['String'];
  unusualMessageFromMidpass: Scalars['String'];
};


export type MutationAddConfirmAppointmentTaskArgs = {
  task: AddConfirmAppointmentTaskInput;
};


export type MutationAddUserArgs = {
  user: AddUserInput;
};


export type MutationAddUserToWaitingListArgs = {
  chatId: Scalars['String'];
  chatUsername?: InputMaybe<Scalars['String']>;
  serviceId: Scalars['String'];
  user: FullUserInput;
};


export type MutationArchiveUserArgs = {
  id: Scalars['String'];
};


export type MutationAttachWaitingAppointmentToUserArgs = {
  appointment: WaitingAppointmentInput;
  chatId: Scalars['String'];
};


export type MutationCancelPreAppointmentArgs = {
  input: CancelPreAppointmentInput;
};


export type MutationChangePassswordArgs = {
  user: ChangePasswordInput;
};


export type MutationConfirmEmailForwardingArgs = {
  input: EmailForwardingConfirmationInput;
};


export type MutationConfirmPreAppointmentArgs = {
  input: ConfitmPreAppointmentInput;
};


export type MutationDisableTaskArgs = {
  id: Scalars['String'];
};


export type MutationOfferPreAppointmentArgs = {
  preAppointment: PreAppointmentInput;
};


export type MutationRejectPreAppointmentArgs = {
  email: Scalars['String'];
};


export type MutationRetryTaskArgs = {
  delay?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};


export type MutationSetAppointmentArgs = {
  appointment: AppointmentInput;
};


export type MutationUnusualMessageFromMidpassArgs = {
  message: UnusualMessageFormMidpassInput;
};

export type PagedUsers = {
  __typename?: 'PagedUsers';
  items: Array<User>;
  total: Scalars['Int'];
};

export type Payment = {
  __typename?: 'Payment';
  amount: Money;
  createdAt: Scalars['String'];
  provider_payment_charge_id: Scalars['String'];
  telegram_payment_charge_id: Scalars['String'];
};

export type PreAppointmentInput = {
  date: Scalars['String'];
  email: Scalars['String'];
  time: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  availableCountInSchedule?: Maybe<AvailableCountInSchedule>;
  confirmations: Array<Confirmation>;
  hello: Scalars['String'];
  infoSiteDiffs?: Maybe<Array<SiteInfoDiffs>>;
  me: Admin;
  password: Scalars['String'];
  serviceSchedule: Array<ServiceSchedule>;
  services: Array<MidPassService>;
  tasks: Array<Task>;
  unmaskEmail: Scalars['String'];
  user?: Maybe<User>;
  users: PagedUsers;
};


export type QueryAvailableCountInScheduleArgs = {
  serviceId: Scalars['String'];
};


export type QueryConfirmationsArgs = {
  fromDate?: InputMaybe<Scalars['String']>;
  toDate?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};


export type QueryPasswordArgs = {
  input: UnmaskEmailInput;
};


export type QueryServiceScheduleArgs = {
  serviceId: Scalars['String'];
};


export type QueryTasksArgs = {
  search: TaskSearchInput;
};


export type QueryUnmaskEmailArgs = {
  input: UnmaskEmailInput;
};


export type QueryUserArgs = {
  id: Scalars['String'];
};


export type QueryUsersArgs = {
  filter: UserSearchInput;
  sort?: InputMaybe<UserSortInput>;
};

export type ServiceSchedule = {
  __typename?: 'ServiceSchedule';
  availableCount: Scalars['Int'];
  date: Scalars['String'];
  scrapeDate: Scalars['String'];
  totalCount: Scalars['Int'];
};

export type SiteInfoDiff = {
  __typename?: 'SiteInfoDiff';
  nextDate: Scalars['String'];
  nextText: Scalars['String'];
  prevDate: Scalars['String'];
  prevText: Scalars['String'];
};

export type SiteInfoDiffs = {
  __typename?: 'SiteInfoDiffs';
  cityName: Scalars['String'];
  diffs: Array<SiteInfoDiff>;
};

/** Sort direction */
export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type Task = {
  __typename?: 'Task';
  email?: Maybe<Scalars['String']>;
  executeAt: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  payload: Scalars['JSONObject'];
  result?: Maybe<Scalars['String']>;
  status: TaskStatus;
  triesLeft: Scalars['Int'];
  userId?: Maybe<Scalars['String']>;
};

export type TaskSearchInput = {
  names?: InputMaybe<Array<Scalars['String']>>;
  statuses?: InputMaybe<Array<TaskStatus>>;
};

export enum TaskStatus {
  Disabled = 'disabled',
  Done = 'done',
  Error = 'error',
  Idle = 'idle',
  Pending = 'pending'
}

export type Telegram = {
  __typename?: 'Telegram';
  id: Scalars['String'];
  username?: Maybe<Scalars['String']>;
};

export type UnmaskEmailInput = {
  id: Scalars['String'];
  otp: Scalars['String'];
};

export type UnusualMessageFormMidpassInput = {
  subject: Scalars['String'];
  text: Scalars['String'];
  to: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  appointment?: Maybe<Appointment>;
  bannedAt?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  id: Scalars['String'];
  lastExecutedTask?: Maybe<Task>;
  nextTask?: Maybe<Task>;
  payment?: Maybe<Payment>;
  service?: Maybe<MidPassService>;
  status?: Maybe<UserStatus>;
  telegram?: Maybe<Telegram>;
};

export type UserSearchInput = {
  email?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<UserStatus>>;
};

export enum UserSortField {
  BannedAt = 'bannedAt',
  Email = 'email',
  LastExecutedTaskExecuteAt = 'lastExecutedTask_executeAt',
  Status = 'status'
}

export type UserSortInput = {
  direction?: InputMaybe<SortDirection>;
  field: UserSortField;
};

export enum UserStatus {
  Appointment = 'appointment',
  PreAppointment = 'pre_appointment',
  Stopped = 'stopped',
  WaitingAppointment = 'waiting_appointment'
}

export type WaitingAppointmentInput = {
  id: Scalars['String'];
  serviceId: Scalars['String'];
};
