import { gql, useQuery } from "@apollo/client";
import { useState } from "react";
import { UserSortField, UserStatus } from "../gql/types";
import { useSorting } from "../utils/sorting";
import { UserListQuery, UserListQueryVariables } from "./hooks.types";
import { LastActionFragment } from "./LastAction/fragment";
import { useParams } from "react-router";

const query = gql`
  query UserList($filter: UserSearchInput!, $sort: UserSortInput!) {
    users(filter: $filter, sort: $sort) {
      total
      items {
        id
        email
        status
        lastExecutedTask {
          id
          ...LastAction
        }
      }
    }
  }

  ${LastActionFragment}
`;

const sortingFields: UserSortField[] = [
  UserSortField.Email,
  UserSortField.Status,
  UserSortField.LastExecutedTaskExecuteAt,
];

export const useUsers = () => {
  const [search, setSearch] = useState<string>("");

  const params = useParams<{ status: UserStatus }>();

  const sort = useSorting(sortingFields);

  const { data, loading } = useQuery<UserListQuery, UserListQueryVariables>(
    query,
    {
      variables: {
        filter: {
          query: search.replace('@', '') || undefined,
          statuses: params.status
            ? [params.status]
            : [UserStatus.WaitingAppointment],
        },
        sort,
      },
    }
  );

  return {
    list: data?.users.items || [],
    total: data?.users.total,
    loading,
    sort,
    search,
    setSearch,
  };
};
