import { useState } from "react";
import { SelectPicker } from "rsuite";
import { useScrappedServices } from "./hooks";

interface Props {
  value: string | undefined;
  onChange: (value: string) => void;
}

export const ServiceSelector: React.FC<Props> = ({ value, onChange }) => {
  const [filterBy, setFilter] = useState<string | undefined>();
  const { list } = useScrappedServices(filterBy);

  return (
    <SelectPicker
      data={list}
      value={value}
      onChange={(value) => value && onChange(value)}
      cleanable={false}
      onSearch={async (keyword, e) => {
        e?.preventDefault();
        e?.stopPropagation();
        setFilter(keyword.toLowerCase());
      }}
      searchBy={() => true}
    />
  );
};
