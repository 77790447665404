import React from "react";
import config from "./config.json";
import { Config } from "./types";

interface Ctx {
  config: Config;
}

export const ctx = {
  // @ts-ignore
  config: config[(process.env.REACT_APP_CONFIG) || "local"],
};

console.log(process.env.REACT_APP_CONFIG)
console.log(ctx);

export const Context = React.createContext<Ctx>(ctx);

