import b_ from "b_";
import React from "react";
import { Button, ButtonToolbar, Panel } from "rsuite";
import { Copy } from "@rsuite/icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { UserStatusVeiw } from "../../UserStatus";
import { UserStatus } from "../../gql/types";
import { Label } from "./../Label";
import { AppointmentFragmentFragment } from "./fragment.types";
import { useActions } from "./hooks";

const b = b_.with("user");

interface Props {
  userId: string;
  status: UserStatus | undefined | null;
  appointment: AppointmentFragmentFragment | undefined | null;
}

export const AppointmentView: React.FC<Props> = ({
  userId,
  status,
  appointment,
}) => {
  const { confirm, cancel } = useActions(userId);
  return (
    <Panel header="Appointment" bordered className={b("panel")}>
      <div>
        <Label>Status</Label>{" "}
        {status && <UserStatusVeiw>{status}</UserStatusVeiw>}
      </div>
      {status === UserStatus.PreAppointment && (
        <div>
          <CopyToClipboard
            text={`Здравствуйте 
Пришло письмо от консульства с предложенными датой и временем визита для подачи документов на оформление загранпаспорта

Дата: ${appointment?.date}
Время: ${appointment?.time}

С предложением можно согласиться или отказаться. В случае отказа запись вернется в лист ожидания
`}
          >
            <Button size="sm">
              <Copy /> Copy invite
            </Button>
          </CopyToClipboard>
        </div>
      )}

      <div>
        <Label>Date</Label> {appointment?.date}
      </div>
      <div>
        <Label>Time</Label> {appointment?.time}
      </div>
      <div>
        <Label>Code</Label> {appointment?.code}
      </div>
      {status === UserStatus.PreAppointment && (
        <ButtonToolbar>
          <Button
            onClick={() => {
              if (
                window.confirm("✅ Do you want to confirm this appointment?")
              ) {
                confirm();
              }
            }}
          >
            ✅ Confirm
          </Button>
          <Button
            onClick={() => {
              if (
                window.confirm("🚫 Do you want to cancel this appointment?")
              ) {
                cancel();
              }
            }}
          >
            🚫 Cancel
          </Button>
        </ButtonToolbar>
      )}
    </Panel>
  );
};
