import { ApolloProvider } from "@apollo/client";
import React from "react";
import { RouterProvider } from "react-router";
import { createBrowserRouter } from "react-router-dom";
import { AddUser } from "./AddUser";
import { Confirmations } from "./Confirmations";
import { Layout } from "./Layout";
import { ServiceSchedule } from "./ServiceSchedule";
import { TaskList } from "./Task";
import { User } from "./User";
import { UserList } from "./Users";
import { generateClient } from "./apollo";
import { Context, ctx } from "./context";
import "./App.css";

require("rsuite/dist/rsuite.min.css");

const client = generateClient(ctx.config.executor.url);

const router = createBrowserRouter([
  {
    path: "/tasks",
    element: (
      <Layout>
        <TaskList />
      </Layout>
    ),
  },
  {
    path: "/confirmations",
    element: (
      <Layout>
        <Confirmations />
      </Layout>
    ),
  },
  {
    path: "/service-schedule",
    element: (
      <Layout>
        <ServiceSchedule />
      </Layout>
    ),
  },
  {
    index: true,
    path: "/users",
    element: (
      <Layout>
        <UserList />
      </Layout>
    ),
  },
  {
    path: "/users/:status",
    element: (
      <Layout>
        <UserList />
      </Layout>
    ),
  },
  {
    path: "/user/add",
    element: (
      <Layout>
        <AddUser />
      </Layout>
    ),
  },
  {
    path: "/user/:id",
    element: (
      <Layout>
        <User />
      </Layout>
    ),
  },
  {
    path: "/login",
    element: <Layout>Login, please</Layout>,
  },
  {
    path: "/",
    element: (
      <Layout>
        <Confirmations />
      </Layout>
    ),
  },
]);

const App: React.FC = () => (
  <Context.Provider value={ctx}>
    <ApolloProvider client={client}>
      <RouterProvider router={router} />
    </ApolloProvider>
  </Context.Provider>
);

export default App;
