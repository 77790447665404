import { gql, useMutation } from "@apollo/client";
import { AddTaskMutation, AddTaskMutationVariables } from "./hooks.types";

const mutation = gql`
  mutation AddTask($task: AddConfirmAppointmentTaskInput!) {
    addConfirmAppointmentTask(task: $task) {
      id
      status
    }
  }
`;

export const useAddUser = () => {
  const [addUser, { loading }] = useMutation<
    AddTaskMutation,
    AddTaskMutationVariables
  >(mutation, { onError: (error) => alert(error.message) });

  return {
    addUser,
    loading,
  };
};
