import React, { forwardRef } from "react";
import { Form } from "rsuite";

interface Props {
  name: string;
  label: string;
  placeholder?: string;
}

export const Field: React.FC<Props> = forwardRef<any, Props>(
  ({ name, label, ...rest }, ref) => (
    <Form.Group controlId={name} ref={ref}>
      <Form.ControlLabel>{label} </Form.ControlLabel>
      <Form.Control name={name} {...rest} />
    </Form.Group>
  )
);
