import { gql, useLazyQuery } from "@apollo/client";
import { UnmaskEmailQuery, UnmaskEmailQueryVariables } from "./hooks.types";

const query = gql`
  query UnmaskEmail($input: UnmaskEmailInput!) {
    unmaskEmail(input: $input)
    password(input: $input)
  }
`;

export const useUnmaskEmail = (id: string, otp: string) => {
  const [load, { data }] = useLazyQuery<
    UnmaskEmailQuery,
    UnmaskEmailQueryVariables
  >(query, {
    variables: { input: { id, otp } },
  });

  return {
    load,
  };
};
