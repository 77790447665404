import React from "react";
import moment from "moment";
import { gql, useQuery } from "@apollo/client";
import { uniq, max, min } from 'lodash';
import {
  ServiceScheduleQuery,
  ServiceScheduleQueryVariables,
} from "./hooks.types";

const query = gql`
  query ServiceSchedule($serviceId: String!) {
    serviceSchedule(serviceId: $serviceId) {
      scrapeDate
      date
      availableCount
      totalCount
    }
  }
`;

export const useSchedule = (serviceId: string) => {
  const { data } = useQuery<
    ServiceScheduleQuery,
    ServiceScheduleQueryVariables
  >(query, {
    variables: {
      serviceId,
    },
  });

  
  const heatmap = React.useMemo(() => {
    const schedule = data?.serviceSchedule || [];
    const y = uniq(schedule.map((s) => s.scrapeDate)).sort();
    const _x = uniq(schedule.map((s) => s.date)).sort();

    const xMin = min(_x)!;
    const xMax = max(_x)!;

    const dateCount = moment(xMax).diff(moment(xMin), 'days');
    const x = new Array(dateCount + 1).fill(0).map((_, i) => moment(xMin).add(i, 'days').format('YYYY-MM-DD'));

    const xMap = x.reduce((acc, curr, i) => {
      acc[curr] = i;
      return acc;
    }, {} as Record<string, number>);

    const yMap = y.reduce((acc, curr, i) => {
      acc[curr] = i;
      return acc;
    }, {} as Record<string, number>);

    const z = new Array(y.length).fill(0).map(() => new Array(x.length).fill(null));

    schedule.forEach((s) => {
      z[yMap[s.scrapeDate]][xMap[s.date]] = s.availableCount;
    });

    return {
      x, 
      y,
      z
    }
    
  }, [data?.serviceSchedule]);

  return {
    heatmap
  }
};
