import { gql, useMutation } from "@apollo/client";

const confirmMutation = gql`
  mutation ConfirmAppointment($id: String!) {
    confirmPreAppointment(input: { userId: $id, delay: 1 })
  }
`;

const cancelMutation = gql`
  mutation CancelAppointment($id: String!) {
    cancelPreAppointment(input: { userId: $id, delay: 1 })
  }
`;

export const useActions = (id: string) => {
  const [ confirm ] = useMutation(confirmMutation, {
    variables: { id },
  });
  const [ cancel ] = useMutation(cancelMutation, {
    variables: { id },
  });

  return {
    confirm,
    cancel
  }
};
