import { gql, useQuery } from "@apollo/client"
import { MeQuery } from "./hooks.types"

const query = gql`
  query Me {
    me {
      email
    }
  }
`

export const useMe = () => {
  const { data } = useQuery<MeQuery>(query)

  return data?.me.email
}