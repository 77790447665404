import { SignInButton } from "../SignInButton";
import { useMe } from "./hooks";

export const User: React.FC = () => {
  const email = useMe();

  if (email) return <div>{email}</div>;

  return <SignInButton />;
};
