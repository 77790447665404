import React from "react";
import { isMobile } from "react-device-detect";
import "./styles.scss";

interface Props {
  user?: {
    id: string;
    username?: string | null;
  } | null;
}

export const TgLink: React.FC<Props> = ({ user }) => {
  if (!user) {
    return null;
  }

  if (isMobile) {
    return (
      <div className="tg-link">
        {user.username && (
          <a href={`https://t.me/${user.username}`} target="_blank">
            {user.username}
          </a>
        )}
        <a href={`https://web.telegram.org/k/#${user.id}`}>id:{user.id}</a>
      </div>
    );
  }

  return (
    <div className="tg-link">
      {user.username && (
        <a
          href={`https://web.telegram.org/k/#@${user.username}`}
          target="_blank"
        >
          {user.username}
        </a>
      )}
      <a href={`https://web.telegram.org/k/#${user.id}`} target="_blank">
        id:{user.id}
      </a>
    </div>
  );
};
