import { Data } from "plotly.js";
import Plot from "react-plotly.js";
import { useSchedule } from "./hooks";

interface Props {
  serviceId: string;
}

export const Chart: React.FC<Props> = ({ serviceId }) => {
  const { heatmap } = useSchedule(serviceId);

  var data: Data[] = [
    {
      ...heatmap,
      type: "heatmap",
      zmin: 0,
      xgap: 1,
      ygap: 1,
      colorscale: 'Portland'
      // hoverongaps: false,
    },
  ];

  return (
    <div>
      <Plot
        data={data}
        layout={{
          width: 1800,
          height: 2000,
          title: "Service schedule",
        }}
      />
    </div>
  );
};
