import React from "react";

import "./styles.scss";

interface Props {
  children: string;
}

export const Label: React.FC<Props> = ({ children }) => {
  return <span className="user-label">{children}:</span>;
};
