import React from "react";
import b_ from "b_";
import { Divider, Stack, Toggle } from "rsuite";
import { taskStatuses } from "../consts";
import { TaskStatus } from "../../gql/types";

import "./styles.scss";

interface Props {
  selectedStatuses: TaskStatus[];
  setStatuses: (statuses: TaskStatus[]) => void;
}

const b = b_.with("task-list-toggles");

export const StatusToggles: React.FC<Props> = ({
  selectedStatuses,
  setStatuses,
}) => (
  <Stack divider={<Divider vertical />} className={b()}>
    {taskStatuses.map((status) => (
      <div key={status}>
        {status}:{" "}
        <Toggle
          checked={selectedStatuses.includes(status)}
          onChange={(value) => {
            if (!value) {
              setStatuses(selectedStatuses.filter((s) => s !== status));
            } else {
              setStatuses([...selectedStatuses, status]);
            }
          }}
        />
      </div>
    ))}
  </Stack>
);
