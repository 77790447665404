import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Copy } from "@rsuite/icons";
import { Button } from "rsuite";

interface Props {
  text: string;
}

export const CopyButton: React.FC<Props> = ({ text }) => (
  <CopyToClipboard text={text}>
    <Button size="xs">
      <Copy />
    </Button>
  </CopyToClipboard>
);
