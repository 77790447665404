import { gql, useQuery } from "@apollo/client";
import React from "react";
import { ServicesQuery } from "./hooks.types";

const query = gql`
  query Services {
    services {
      id
      name
      alternativeName
      city {
        name
        country {
          name
        }
      }
    }
  }
`;

export const useScrappedServices = (filterBy: string | undefined) => {
  const { data } = useQuery<ServicesQuery>(query);

  const list = React.useMemo(
    () =>
      (data?.services || []).map((item) => ({
        label: `${item.name} - ${item.city?.name} - ${item.city?.country?.name}`,
        value: item.id,
        search:
          `${item.name} ${item.city?.name} ${item.city?.country?.name}`.toLowerCase(),
      })),
    [data]
  );

  const filtered = React.useMemo(() => {
    if (!filterBy) return list;
    const parts = filterBy
      .split(" ")
      .map((part) => part.trim().toLowerCase())
      .filter(Boolean);

    return list.filter((item) =>
      parts.every((part) => item.search.includes(part))
    );
  }, [list, filterBy]);

  return {
    list: filtered,
  };
};
