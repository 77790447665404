import React from "react";
import { UserStatus } from "../gql/types";

interface Props {
  children: UserStatus;
}

const statusToEmoji: Record<UserStatus, string> = {
  [UserStatus.Appointment]: "✅",
  [UserStatus.PreAppointment]: "🏃‍♂",
  [UserStatus.Stopped]: "🚫",
  [UserStatus.WaitingAppointment]: "⏳",
};

export const UserStatusVeiw: React.FC<Props> = ({ children: status }) => (
  <>
    {statusToEmoji[status]} {status}
  </>
);
