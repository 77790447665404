import { useState, useLayoutEffect, useRef } from "react";

export const useDimensions = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [dimensions, setDimensions] = useState<DOMRect | undefined>();

  const measure = () => setDimensions(ref.current?.getBoundingClientRect());

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }
    measure();

    window.addEventListener("resize", measure);

    return () => {
      window.removeEventListener("resize", measure);
    };
  }, [ref]);

  return { ref, dimensions };
};
