import { gql, useQuery } from "@apollo/client";
import { UserQuery, UserQueryVariables } from "./hooks.types";
import { AppointmentFragment } from "./Appointment/fragment";

const query = gql`
  query User($id: String!) {
    user(id: $id) {
      id
      email
      status
      telegram {
        id
        username
      }
      bannedAt
      appointment {
        ...AppointmentFragment
      }
      service {
        name
        alternativeName
        city {
          name
        }
      }

      payment {
        createdAt
        amount {
          value
          currency
        }
        telegram_payment_charge_id
      }

      lastExecutedTask {
        id
        name
        executeAt
        result
        status
      }

    }
  }

  ${AppointmentFragment}
`;

export const useUser = (id: string | undefined) => {
  const { data, loading } = useQuery<UserQuery, UserQueryVariables>(query, {
    variables: { id: id || "" },
    skip: !id,
  });

  return {
    user: data?.user,
    loading,
  };
};
