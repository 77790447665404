import React from "react";
import { Navbar, Nav, Header as RHeader } from "rsuite";
import { User } from "../../AuthSection";
import { NavLink } from "react-router-dom";

export const Header: React.FC = () => (
  <RHeader>
    <Navbar>
      <Navbar.Brand>Queue bot admin</Navbar.Brand>
      <Nav>
        <Nav.Item to="/confirmations" as={NavLink}>
          Confirmations
        </Nav.Item>
        <Nav.Item to="/tasks" as={NavLink}>
          Tasks
        </Nav.Item>
        <Nav.Item to="/users" as={NavLink}>
          Users
        </Nav.Item>
        <Nav.Item to="/service-schedule" as={NavLink}>
          Service schedule
        </Nav.Item>
      </Nav>
      <Nav pullRight>
        <Nav.Item>
          <User />
        </Nav.Item>
      </Nav>
    </Navbar>
  </RHeader>
);
