import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ConfirmationsQuery, ConfirmationsQueryVariables } from "./hooks.types";
import moment from "moment";
import { debounce, min, uniqBy, values } from "lodash";

const query = gql`
  query Confirmations($userId: String, $fromDate: String, $toDate: String) {
    confirmations(userId: $userId, fromDate: $fromDate, toDate: $toDate) {
      user {
        id
        email
      }
      data
    }
  }
`;

interface User {
  id: string;
  email: string;
}

type Point = [string, number];
interface Track {
  user: User;
  confirmations: Point[];
}

export const useConfirmations = (userId: string | undefined, defaultFromDate?: string) => {
  const [fetchData, { data, loading }] = useLazyQuery<
    ConfirmationsQuery,
    ConfirmationsQueryVariables
  >(query, { variables: { userId }, partialRefetch: true });

  const [tracks, setTracks] = useState<Track[]>([]);

  const [fromDate, setFromDate] = useState(
    defaultFromDate || moment().utc().subtract(3, "month").format()
  );

  const [toDate, setToDate] = useState(moment().utc().format());

  const update = useCallback(
    debounce(
      (fromDate: string) => {
        const toDate = min(
          tracks.map(({ confirmations }) => confirmations[0][0])
        );
        if (
          toDate &&
          +moment(toDate).toDate() - +moment(fromDate).toDate() <
            24 * 60 * 60 * 1000
        )
          return;
        fetchData({ variables: { userId, fromDate, toDate } });
      },
      500,
      { trailing: true, leading: false }
    ),
    [setFromDate, fetchData, tracks]
  );

  const updateRange = (fromDate: string, toDate: string) => {
    setFromDate(fromDate);
    setToDate(toDate);
    update(fromDate);
  };

  const list = data?.confirmations;

  useEffect(() => {
    if (!list?.length) return;
    setTracks((prev) => {
      const byUser = prev.reduce((acc, item) => {
        acc[item.user.id] = item;
        return acc;
      }, {} as Record<string, Track>);
      const result = (list || []).reduce(
        (acc, item) => {
          const userId = item.user.id;
          if (acc[userId]) {
            acc[userId].confirmations = uniqBy(
              [...(item.data.points as Point[]), ...acc[userId].confirmations],
              ([date]) => date
            );
          }
          acc[userId] = acc[userId] || {
            user: item.user,
            confirmations: item.data.points,
          };

          return acc;
        },
        { ...byUser }
      );

      return values(result);
    });
  }, [list]);

  return {
    loading,
    tracks,
    fromDate,
    toDate,
    updateRange,
  };
};

const conmpareDates = (a: string, b: string) => {
  if (a > b) return 1;
  if (a < b) return -1;
  return 0;
};
