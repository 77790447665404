interface Money {
  value: number;
  currency: string;
}

interface Props {
  children: Money | null | undefined;
}

export const Money: React.FC<Props> = ({ children }) => {
  if (!children) {
    return <>-</>;
  }
  const { value, currency } = children;
  return (
    <>
      {value / 100} {currency}
    </>
  );
};
