import { SortDirection } from "../gql/types";
import b_ from "b_";
import './styles.scss'

const b = b_.with("table-header");
interface Sorting<T> {
  field: T;
  direction: SortDirection;
  toggleDirection: (field: T) => void;
}

interface Props<T> {
  children: React.ReactNode;
  field: T;
  sorting: Sorting<T>;
}

export function TableHeader<T extends string>({
  children,
  field,
  sorting,
}: Props<T>) {
  return (
    <th className={b({sortable: true})} onClick={() => sorting.toggleDirection(field)}>
      {children}
      {field === sorting.field
        ? sorting.direction === SortDirection.Asc
          ? " ▲"
          : " ▼"
        : null}
    </th>
  );
}
