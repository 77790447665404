import React from "react";
import { Link } from "react-router-dom";
import b_ from "b_";
import { UserSortField } from "../gql/types";
import { useUsers } from "./hooks";
import { StatusSubmenu } from "./StatusSubmenu";
import { Search } from "./Search";
import { LastAction } from "./LastAction";
import { TableHeader } from "../TableHeader";

import "./styles.scss";
import { Button } from "rsuite";

const b = b_.with("user-list");

export const UserList: React.FC = () => {
  const { list, loading, search, setSearch, sort } = useUsers();

  return (
    <div>
      <div className={b("add-user")}>
        <Button appearance="ghost" href="/user/add">
          Add user
        </Button>
      </div>
      <h2>User list</h2>
      <Search value={search} onChange={setSearch} />
      <StatusSubmenu />
      <div className={b("count")}>{loading ? "..." : list.length}</div>
      <div className={b("table-container")}>
        <table>
          <thead className={b("head")}>
            <tr>
              <TableHeader field={UserSortField.Email} sorting={sort}>
                Email
              </TableHeader>
              <TableHeader field={UserSortField.Status} sorting={sort}>
                Status
              </TableHeader>
              <TableHeader
                field={UserSortField.LastExecutedTaskExecuteAt}
                sorting={sort}
              >
                Last action result
              </TableHeader>
            </tr>
          </thead>
          <tbody className={b("table")}>
            {list.map((item) => (
              <tr key={item.id}>
                <td>
                  <Link to={`/user/${item.id}`}>{item.email}</Link>
                </td>
                <td>{item.status}</td>
                <td>
                  <LastAction action={item.lastExecutedTask} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
