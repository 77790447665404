import { Input } from "rsuite";

interface Props {
  value: string;
  onChange: (value: string) => void;
}

export const Search: React.FC<Props> = ({ value, onChange }) => {
  return (
    <Input placeholder="Search by email or tg username" value={value} onChange={onChange} />
  );
};
