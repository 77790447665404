import { useEffect, useState } from "react";
import moment from "moment";

interface Props {
  children: string;
  format?: string;
}

export const TimeDiff: React.FC<Props> = ({ children: dateTime, format }) => {
  const [diff, setDiff] = useState(getTimeDiff(dateTime, format));

  useEffect(() => {
    const interval = setInterval(() => {
      setDiff(getTimeDiff(dateTime, format));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return <>{diff}</>;
};

const getTimeDiff = (time: string, format = "HH:mm:ss") => {
  const diff = moment(time).utc().diff(moment().utc());
  const prefix = diff > 0 ? "через" : "прошло";
  const absDiff = Math.abs(diff);

  const d = Math.floor(absDiff / (1000 * 60 * 60 * 24));
  const rest = absDiff % (1000 * 60 * 60 * 24);

  return `${prefix} ${d > 0 ? `${d}д. ` : ""}${moment(rest)
    .utc()
    .format(format)}`;
};
