import React from "react";
import { LastActionFragment } from "./fragment.types";
import { TimeDiff } from "../../TimeDiff";
import { TaskStatusView } from "../../TaskStatus";

interface Props {
  action: LastActionFragment | undefined | null;
}

export const LastAction: React.FC<Props> = ({ action }) => {
  if (!action) {
    return <div>-</div>;
  }
  return (
    <div title={action.executeAt}>
      <TaskStatusView>{action.status}</TaskStatusView>{" "}
      <TimeDiff format="HH:mm">{action.executeAt}</TimeDiff>
    </div>
  );
};
