import { useCallback, useState } from "react";
import { SortDirection } from "../gql/types";

export const useSorting = <T extends string>(fields: T[]) => {
  const [field, setField] = useState(fields[0]);
  const [direction, setDirection] = useState(SortDirection.Asc);

  const toggleDirection = useCallback(
    (newField: T) => {
      if (newField === field) {
        setDirection(direction => 
          direction === SortDirection.Asc
            ? SortDirection.Desc
            : SortDirection.Asc
        );
        return;
      }

      setField(newField);
      setDirection(SortDirection.Asc);
    },
    [fields, field, direction]
  );

  return {
    field,
    direction,
    toggleDirection,
  };
};
