import React from "react";
import ResponsiveNav from "@rsuite/responsive-nav";
import b_ from "b_";
import { useMatch, useNavigate } from "react-router";
import { UserStatus } from "../../gql/types";
import { userStatuses } from "../consts";

import "./styles.scss";

const b = b_.with("user-list-toggles");

export const StatusSubmenu: React.FC = ({}) => {
  const navigate = useNavigate();
  const match = useMatch("/users/:status");
  return (
    <ResponsiveNav
      appearance="tabs"
      className={b("submenu")}
      activeKey={match?.params.status || UserStatus.WaitingAppointment}
      onSelect={(tab) => navigate(`/users/${tab}`)}
    >
      {userStatuses.map((status) => (
        <ResponsiveNav.Item eventKey={status}>{status}</ResponsiveNav.Item>
      ))}
    </ResponsiveNav>
  );
};
