import { useCallback, useState } from "react";
import { Button, Input, Modal } from "rsuite";
import { useUnmaskEmail } from "./hooks";

interface Props {
  id: string;
  setCreds: (creds: [string, string]) => void;
}

export const UnmaskEmail: React.FC<Props> = ({ id, setCreds }) => {
  const [open, setOpen] = useState(false);
  const [otp, setOtp] = useState("");
  const { load } = useUnmaskEmail(id, otp);

  const click = useCallback(async () => {
    const response = await load();
    if (!response.data?.unmaskEmail) {
      return;
    }
    setCreds([response.data.unmaskEmail, response.data.password || '']);
    setOpen(false);
  }, [load, setCreds]);

  return (
    <div>
      <Button onClick={() => setOpen(true)}>Unmask</Button>

      <Modal open={open} onClose={() => setOpen(false)}>
        <Modal.Header>
          <Modal.Title>Unmask email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Input value={otp} onChange={setOtp} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={click} appearance="primary">
            Ok
          </Button>
          <Button onClick={() => setOpen(false)} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
