import { useState } from "react";
import { ServiceSelector } from "../ServiceSelector";
import { Chart } from "./Chart";

export const ServiceSchedule: React.FC = () => {
  const [serviceId, setServiceId] = useState<string | undefined>();

  return (
    <div>
      <ServiceSelector value={serviceId} onChange={setServiceId} />
      {serviceId && <Chart serviceId={serviceId} />}
    </div>
  );
};
