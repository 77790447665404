import React, { useCallback, useRef, useState } from "react";
import { Button, ButtonToolbar, Form, FormInstance, Schema } from "rsuite";
import { useAddUser } from "./hooks";
import { AddConfirmAppointmentTaskInput as Payload } from "../gql/types";
import { Field } from "../Field";
import { ServiceSelector } from "../ServiceSelector";
import { useNavigate } from "react-router";

const { StringType, NumberType } = Schema.Types;

const model = Schema.Model<Payload>({
  email: StringType()
    .isEmail("Please enter a valid email address.")
    .isRequired("This field is required."),

  chatId: NumberType().isRequired("This field is required."),

  delay: NumberType("Please enter a valid number.").range(
    1,
    1440,
    "Please enter a number from 1 to 1440"
  ),

  serviceId: StringType()
    .isRequired("This field is required.")
    .pattern(
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i,
      "Please enter a valid service id"
    ),
});

interface Props {}
export const AddUser: React.FC<Props> = ({}) => {
  const { addUser, loading } = useAddUser();
  const ref = useRef<FormInstance<Payload>>(null);
  const navigate = useNavigate();

  const [formValue, setFormValue] = useState<Payload>({
    email: "",
    serviceId: "bb064812-a917-248e-d17c-2cf57b9f8cb2",
    delay: 1440,
    chatId: "",
  });

  const handleSubmit = useCallback(async () => {
    if (!ref.current?.check()) {
      return;
    }
    const response = await addUser({ variables: { task: {
      ...formValue,
      delay: +formValue.delay,
    } } });
    const id = response.data?.addConfirmAppointmentTask.id;
    if (id) {
      navigate(`/user/${id}`);
    }
  }, [navigate, formValue, ref.current, addUser]);

  return (
    <div>
      <Form
        ref={ref}
        model={model}
        formValue={formValue}
        onChange={(value: any) => setFormValue(value)}
        onSubmit={handleSubmit}
      >
        <Form.Group>
          <Form.ControlLabel>Service</Form.ControlLabel>
          <ServiceSelector
            value={formValue.serviceId}
            onChange={(serviceId) => setFormValue({ ...formValue, serviceId })}
          />
          <Form.HelpText>{formValue.serviceId}</Form.HelpText>
        </Form.Group>
        <Field name="email" label="Email" />
        <Field name="delay" label="Delay" />
        <Field name="chatId" label="Chat id" />
        <Form.Group>
          <ButtonToolbar>
            <Button
              appearance="primary"
              disabled={!ref.current?.check()}
              loading={loading}
              type="submit"
            >
              Add user
            </Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </div>
  );
};
