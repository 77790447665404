import { gql } from "@apollo/client";

export const LastActionFragment = gql`
  fragment LastAction on Task {
    id
    name
    executeAt
    result
    status
  }
`;
