import React from "react";
import { Container, Content } from "rsuite";
import { Header } from "./Header";

import './styles.scss';

interface Props {
  children?: React.ReactNode;
}

export const Layout: React.FC<Props> = ({ children }) => (
  <div>
    <Container>
      <Header />
      <Content className='layout'>{children}</Content>
    </Container>
  </div>
);
