import SpinnerIcon from "@rsuite/icons/legacy/Spinner";
import ColorHash from "color-hash";
import { Data, PlotData } from "plotly.js";
import React, { useEffect, useMemo, useState } from "react";
import Plot from "react-plotly.js";
import { useDimensions } from "../utils/dimensions";
import { useConfirmations } from "./hooks";
import "./styles.scss";
import { max, min } from "lodash";

const colorHash = new ColorHash();

interface Props {
  userId?: string;
  fromDate?: string;
}

export const Confirmations: React.FC<Props> = ({
  userId,
  fromDate: defaultFromDate,
}) => {
  const { loading, tracks, fromDate, toDate, updateRange } = useConfirmations(
    userId,
    defaultFromDate
  );

  const { ref, dimensions } = useDimensions();

  const [yRange, setYRange] = useState<[number, number]>();

  const data = useMemo<Data[]>(
    () =>
      tracks.map<Data>((track) => ({
        x: track.confirmations.map((item) => item[0]),
        y: track.confirmations.map((item) => item[1]),
        type: "scatter",
        mode: "lines+markers",
        name: track.user.email,
        marker: {
          color: colorHash.hex(`${userId}_${track.user.email}`),
        },
      })),
    [tracks]
  );

  const defaultYRange = useMemo(() => {
    if (!tracks.length) return;
    return tracks.reduce<[number, number]>(
      (acc, track) => {
        const y = track.confirmations.map((item) => item[1]);
        return [
          Math.min(acc[0], min(y) || acc[0]),
          Math.max(acc[1], max(y) || acc[1]),
        ];
      },
      [Infinity, -Infinity]
    );
  }, [tracks]);

  const layout = useMemo(
    () => ({
      xaxis: {
        autorange: false,
        range: [fromDate, toDate],
      },

      yaxis: {
        autorange: false,
        range: yRange || [0, 4000],
      },
      autosize: false,

      width: dimensions?.width || 1800,
      height: 800,
      title: "Confirmations",
      showlegend: dimensions ? dimensions?.width > 1000 : true,
    }),
    [dimensions, fromDate, toDate, yRange]
  );

  useEffect(() => {
    if(!layout.yaxis?.range && defaultYRange?.length) {
      setYRange(defaultYRange);
    }
  }, [defaultYRange, layout])


  return (
    <div ref={ref} className="container">
      <div className="spinner">
        {loading && <SpinnerIcon pulse style={{ fontSize: "1em" }} />}
      </div>
      <Plot
        data={data}
        config={{ displayModeBar: true }}
        onUpdate={({ layout }) => {
          const fromDate = layout.xaxis?.range?.[0];
          const toDate = layout.xaxis?.range?.[1];
          fromDate && toDate && updateRange(fromDate, toDate);
          const yRange = layout.yaxis?.range as [number, number];

          setYRange(yRange || defaultYRange);
        }}
        layout={layout}
      />
    </div>
  );
};
