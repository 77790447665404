import { gql, useMutation, useQuery } from "@apollo/client";
import { TaskStatus } from "../gql/types";
import { ArchiveTaskMutation, ArchiveTaskMutationVariables, RetryTaskMutation, RetryTaskMutationVariables, TaskListQuery, TaskListQueryVariables } from "./hooks.types";

const query = gql`
  query TaskList($statuses: [TaskStatus!]) {
    tasks(search: { statuses: $statuses }) {
      id
      name
      userId
      executeAt
      status
      result
      email
    }
  }
`;

const archiveMutation = gql`
  mutation ArchiveTask($id: String!) {
    disableTask(id: $id)
  }
`;

const retryMutation = gql`
  mutation RetryTask($id: String!) {
    retryTask(id: $id)
  }
`;


export const useTaskList = (statuses: TaskStatus[]) => {
  const { data, loading } = useQuery<TaskListQuery, TaskListQueryVariables>(
    query,
    {
      variables: {
        statuses: statuses.length > 0 ? statuses : undefined,
      },
      pollInterval: 10_000,
    }
  );

  return {
    list: data?.tasks,
    loading,
  };
};

export const useArchive = () => {
  const [mutation] = useMutation<ArchiveTaskMutation, ArchiveTaskMutationVariables>(archiveMutation, {
    refetchQueries: ["TaskList"],
    
    update: (cache, { data }) => {
      if (data?.disableTask) {
        cache.modify({
          fields: {
            tasks: (existingTasks = [], { readField }) => {
              return existingTasks.filter(
                (taskRef: any) => readField("id", taskRef) !== data.disableTask
              );
            },
          },
        });
      }
    }
  });

  return {
    archive: (id: string) => mutation({
      variables: {
        id
      }
    })
  }
};




export const useRetry = () => {
  const [mutation] = useMutation<RetryTaskMutation, RetryTaskMutationVariables>(retryMutation, {
    refetchQueries: ["TaskList"],
  });

  return {
    retry: (id: string) => mutation({
      variables: {
        id
      }
    })
  }
};
