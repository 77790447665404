import b_ from "b_";
import moment from "moment";
import React, { useState } from "react";
import { useParams } from "react-router";
import { Panel } from "rsuite";
import { Confirmations } from "../Confirmations";
import { CopyButton } from "../CopyButton";
import { Money } from "../Money";
import { TaskStatusView } from "../TaskStatus";
import { TimeDiff } from "../TimeDiff";
import { AppointmentView } from "./Appointment";
import { Label } from "./Label";
import { TgLink } from "./TgLink";
import { UnmaskEmail } from "./UnmaskEmail";
import { useUser } from "./hooks";
import "./styles.scss";

const b = b_.with("user");

export const User: React.FC = () => {
  let { id } = useParams<"id">();
  const { user } = useUser(id);

  const [creds, setCreds] = useState<[string, string] | undefined>();

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Panel header="User" bordered className={b("panel")}>
        <div>
          {!creds ? (
            <div>
              <Label>Email</Label> {user.email}
            </div>
          ) : (
            <>
              <div>
                <Label>Email</Label> {creds[0]}{" "}
                <CopyButton text={creds[0] || ""} />
              </div>

              <div>
                <Label>Password</Label> {creds[1]}{" "}
                <CopyButton text={creds[1] || ""} />
              </div>
            </>
          )}
          <UnmaskEmail id={user.id} setCreds={setCreds} />
        </div>
        <div className={b('row')}>
          <Label>Tg</Label> <TgLink user={user.telegram} />
        </div>
      </Panel>

      <Panel header="Service" bordered className={b("panel")}>
        <div>
          <Label>Name</Label> {user.service?.name}{" "}
          {user.service?.alternativeName}
        </div>
        <div>
          <Label>City</Label> {user.service?.city?.name}
        </div>
        {user.bannedAt && (
          <div>
            <Label>Last ban</Label>{" "}
            <span title={user.bannedAt}>
              {moment(user.bannedAt).format("DD.MM.YYYY HH:mm")}
            </span>
          </div>
        )}
      </Panel>

      {id && (
        <AppointmentView
          userId={id}
          status={user.status}
          appointment={user.appointment}
        />
      )}
      {user.lastExecutedTask && (
        <Panel header="Last task" bordered className={b("panel")}>
          <div>
            <Label>ID</Label> {user.lastExecutedTask.id}
          </div>

          <div>
            <Label>Name</Label> {user.lastExecutedTask.name}
          </div>

          <div>
            <Label>Executed at</Label> {user.lastExecutedTask.executeAt}{" "}
            <TimeDiff format="HH:mm:ss">
              {user.lastExecutedTask.executeAt}
            </TimeDiff>
          </div>
          <div>
            <Label>Result</Label>{" "}
            <TaskStatusView>{user.lastExecutedTask.status}</TaskStatusView>{" "}
            {user.lastExecutedTask.result}
          </div>
        </Panel>
      )}
      <Panel header="Payment" bordered collapsible className={b("panel")}>
        <div>
          <Label>Amount</Label> <Money>{user.payment?.amount}</Money>
        </div>
        <div>
          <Label>Id</Label> {user.payment?.telegram_payment_charge_id}
        </div>
        <div>
          <Label>Date</Label> {user.payment?.createdAt}
        </div>
      </Panel>

      <Confirmations userId={user.id} fromDate="2023-01-01T00:00:00Z" />
    </div>
  );
};
