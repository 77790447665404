import React from "react";
import { TaskStatus } from "../gql/types";

interface Props {
  children: TaskStatus;
}

const statusToEmoji: Record<TaskStatus, string> = {
  [TaskStatus.Done]: "✅",
  [TaskStatus.Error]: "❌",
  [TaskStatus.Idle]: "⏳",
  [TaskStatus.Pending]: "🏃‍♂",
  [TaskStatus.Disabled]: "🚫",
}

export const TaskStatusView: React.FC<Props> = ({ children: status }) => {
  return (
    <span title={status}>
      {statusToEmoji[status] || "❓"}
    </span>
  );
};
